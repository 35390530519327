import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import theme from "../../util/theme";

const ThemeContextProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(ThemeContextProvider);
