import styled, { keyframes } from "styled-components";

const breathing = keyframes`
 0% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  25% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  60% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
`;

export const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ asOverlay }) =>
    asOverlay &&
    `
      position: absolute;
      z-index: 9999999;
      top: 0;
      left: 0;
 `};

  background: ${({ transparent, asOverlay }) =>
    !transparent && asOverlay && "rgba(0,0,0,0.4)"};
`;

export const StyledIcon = styled.svg`
  animation: ${breathing} 1.5s ease-out infinite normal;
  width: 5rem;
  height: 5rem;

  & g {
    fill: ${({ theme }) => theme.decFirst};
  }
  ${({ theme }) => theme.mq.desktop} {
    width: 10rem;
    height: 10rem;
  }
`;
