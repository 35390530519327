import { useState, useCallback, useEffect } from "react";

import useHttpClient from "./httpHook";
import { backendUrl } from "../util/constants";

let logoutTimer;

const useAuth = () => {
  const [uToken, setUToken] = useState();
  const [uId, setUId] = useState();
  const [uExpiration, setUExpiration] = useState();
  const [user, setUser] = useState();
  const [avatar, setAvatar] = useState(null);
  const [role, setRole] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { sendRequest } = useHttpClient();

  const logout = useCallback(() => {
    setUToken(null);
    setUId(null);
    setUExpiration(null);
    setUser(null);
    setRole("guest");
    setIsLoggedIn(false);
    localStorage.removeItem("userData");
  }, []);

  const getUser = useCallback(
    async (jwt) => {
      try {
        const response = await sendRequest(
          `${backendUrl}/users/me`,
          "GET",
          null,
          {
            Authorization: `Bearer ${jwt}`,
          }
        );
        if (response && response.status === "success") {
          setRole(response.data.role);
          setUser({
            id: response.data.id,
            name: response.data.name,
            surname: response.data.surname,
            email: response.data.email,
            priceLevel: response.data.priceLevel && response.data.priceLevel,
            phone: response.data.phoneNumber,
          });
          setAvatar(response.data.photo && response.data.photo);
        }
      } catch (err) {
        logout();
      }
    },
    [sendRequest, logout]
  );

  const login = useCallback(
    ({ token, userId, uRole, expiration }) => {
      setUToken(token);
      setUId(userId);
      setRole(uRole);
      setUExpiration(expiration);

      if (token) {
        setIsLoggedIn(true);
        getUser(token);
      }

      localStorage.setItem(
        "userData",
        JSON.stringify({
          token,
          userId,
          uRole,
          expiration,
        })
      );
    },
    [getUser]
  );

  const keepBeingLogedIn = useCallback(
    ({ token, userId, uRole, expiration }) => {
      setUToken(token);
      setUId(userId);
      setRole(uRole);
      setUExpiration(expiration);

      if (token) {
        setIsLoggedIn(true);
        getUser(token);
      }
    },
    [getUser]
  );

  const updateMe = (id, data) => {
    setUser((p) => {
      const temp = p;
      temp.name = data.name;
      temp.surname = data.surname;
      temp.phone = data.phoneNumber;
      return temp;
    });
  };

  useEffect(() => {
    if (uToken && uExpiration) {
      const remainingTime =
        new Date(uExpiration).getTime() - new Date(Date.now());
      // Max number for setTimout is 2147483647 due to 32bit int limit, so just check if it needs to logout in next hour
      if (remainingTime < 60 * 60 * 1000) {
        logoutTimer = setTimeout(logout, remainingTime);
      }
    } else {
      clearTimeout(logoutTimer);
    }
  }, [uToken, uExpiration, logout]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      keepBeingLogedIn(storedData);
    } else {
      setRole("guest");
    }
  }, [keepBeingLogedIn]);

  return {
    uToken,
    isLoggedIn,
    uId,
    user,
    avatar,
    setAvatar,
    role,
    updateMe,
    login,
    logout,
  };
};

export default useAuth;
