import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const StyledLink = styled.a`
  margin: 0.5rem 0 0 0;
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.textFirst};
  font-size: 16px;
  font-weight: 400;

  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.decFirst};
  }

  ${({ theme }) => theme.mq.desktop} {
    margin: 0 0 0 0.5rem;
    display: inline;
  }
`;
