import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

import { StyledLink } from './CookieBanner.style';

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Zgadzam się"
      cookieName="AcceptedCookies"
      style={{
        background: '#484848',
        color: '#f0f0f0',
        alignItems: 'center',
      }}
      buttonStyle={{
        background: '#f0f0f0',
        borderRadius: '2px',
        color: '#222222',
        fontWeight: '400',
      }}
    >
      Ta strona korzysta z plików cookie, żeby zwiększyć komfort użytkowania.{' '}
      <StyledLink as={Link} to="/polityka-prywatnosci">
        Polityka Prywatności
      </StyledLink>
    </CookieConsent>
  );
};

export default CookieBanner;
