import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AuthContextProvider from "./shared/context/auth/AuthContextProvider";
import ThemeContextProvider from "./shared/context/theme/ThemeContextProvider";
import "./index.css";

ReactDOM.render(
  <AuthContextProvider>
    <ThemeContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
