import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Nlb } from "../../shared/util/icons/Nlb.svg";
import { StyledWrapper, StyledIcon } from "./LoadingSpinner.style";

const LoadingSpinner = ({ asOverlay, transparent }) => {
  return (
    <StyledWrapper asOverlay={asOverlay} transparent={transparent}>
      <StyledIcon as={Nlb} />
    </StyledWrapper>
  );
};

LoadingSpinner.propTypes = {
  asOverlay: PropTypes.bool,
  transparent: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  asOverlay: false,
  transparent: false,
};

export default LoadingSpinner;
