import React, { Suspense } from "react";
import ReactGA from "react-ga";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import MyHelmet from "./components/MyHelmet/MyHelmet";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import { gaKey } from "./shared/util/constants";

const Home = React.lazy(() => import("./views/Home/Home"));
const Auth = React.lazy(() => import("./views/Auth/Auth"));
const Visits = React.lazy(() => import("./views/AdminCockpit/Visits/Visits"));
const Services = React.lazy(() =>
  import("./views/AdminCockpit/Services/Services")
);
const Worekrs = React.lazy(() =>
  import("./views/AdminCockpit/Workers/Workers")
);
const Users = React.lazy(() => import("./views/AdminCockpit/Users/Users"));
const Gallery = React.lazy(() =>
  import("./views/AdminCockpit/Gallery/Gallery")
);
const WorkingTime = React.lazy(() =>
  import("./views/AdminCockpit/WorkingTime/WorkingTime")
);
const WorkerAccount = React.lazy(() =>
  import("./views/AdminCockpit/WorkerAccount/WorkerAccount")
);
const ConfirmAccount = React.lazy(() =>
  import("./views/ConfirmAccount/ConfirmAccount")
);
const ResetPassword = React.lazy(() =>
  import("./views/ResetPassword/ResetPassword")
);
const Regulations = React.lazy(() => import("./views/Regulations/Regulations"));
const PrivacyPolicy = React.lazy(() =>
  import("./views/PrivacyPolicy/PrivacyPolicy")
);

ReactGA.initialize(gaKey);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const routes = (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/umów">
        <Home open />
      </Route>
      <Route path="/autentykacja">
        <Auth />
      </Route>
      <Route path="/weryfikacja/:token?">
        <ConfirmAccount />
      </Route>
      <Route path="/nowe_haslo/:token?">
        <ResetPassword />
      </Route>
      <Route path="/regulamin">
        <Regulations />
      </Route>
      <Route path="/polityka-prywatnosci">
        <PrivacyPolicy />
      </Route>
      <ProtectedRoute path="/kokpit/wizyty" requiredRole={["admin", "worker"]}>
        <Visits />
      </ProtectedRoute>
      <ProtectedRoute path="/kokpit/uslugi" requiredRole={["admin"]}>
        <Services />
      </ProtectedRoute>
      <ProtectedRoute path="/kokpit/pracownicy" requiredRole={["admin"]}>
        <Worekrs />
      </ProtectedRoute>
      <ProtectedRoute path="/kokpit/uzytkownicy" requiredRole={["admin"]}>
        <Users />
      </ProtectedRoute>
      <ProtectedRoute path="/kokpit/galeria" requiredRole={["admin"]}>
        <Gallery />
      </ProtectedRoute>
      <ProtectedRoute path="/kokpit/godziny" requiredRole={["admin"]}>
        <WorkingTime />
      </ProtectedRoute>
      <ProtectedRoute path="/kokpit/konto" requiredRole={["worker"]}>
        <WorkerAccount />
      </ProtectedRoute>
      <Redirect to="/" />
    </Switch>
  );

  return (
    <>
      <MyHelmet />
      <CookieBanner />
      <ScrollToTop />
      <Suspense fallback={<LoadingSpinner asOverlay />}>{routes}</Suspense>
    </>
  );
};

export default App;
