import { useState, useCallback, useRef, useEffect } from "react";

const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortController = new AbortController();
      activeHttpRequests.current.push(httpAbortController);

      let mounted = true;
      try {
        let response;
        try {
          response = await fetch(url, {
            method,
            body,
            headers,
            signal: httpAbortController.signal,
          });
        } catch (err) {
          if (err.message === "Użytkownik przerwał żądanie.") {
            mounted = false;
          }
        }
        if (mounted) {
          if (response) {
            let responseData;
            if (response && method !== "DELETE" && response.status !== 429) {
              responseData = await response.json();
            }

            activeHttpRequests.current = activeHttpRequests.current.filter(
              (reqController) => reqController !== httpAbortController
            );

            if (!response.ok) {
              if (response.status === 429) {
                setError(
                  "Zbyt wiele zapytań z tego adresu IP, proszę spróbować ponownie za 30 minut."
                );
              } else {
                setError(
                  (responseData && responseData.message) ||
                    "Coś poszło nie tak."
                );
              }
            }

            setIsLoading(false);
            return responseData;
          }
          setError("Brak połączenia z serwerem");
        }
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
        return err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortController) =>
        abortController.abort()
      );
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};

export default useHttpClient;
