import React from "react";
import { Helmet } from "react-helmet";
import desktopOg from "../../shared/util/images/desktopOg.jpg";

const MyHelmet = () => {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>Next Level Barber - Fryzjer Męski</title>
      <link rel="canonical" href="http://nextlevelbarber.pl" />
      <meta
        name="description"
        content="Klasyczne fryzury męskie i idealne brody. Next Level Barber to najlepszy Barber Shop w Krakowie - wynosimy fryzjerstwo na następny poziom."
      />
      <meta property="og:locale" content="pl_PL" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Next Level Barber - Fryzjer Męski" />
      <meta
        property="og:description"
        content="Klasyczne fryzury męskie i idealne brody. Next Level Barber to najlepszy Barber Shop w Krakowie - wynosimy fryzjerstwo na następny poziom."
      />
      <meta property="og:url" content="https://nextlevelbarber.pl/" />
      <meta property="og:site_name" content="Next Level Barber" />
      <meta property="og:image" content={desktopOg} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="457" />
    </Helmet>
  );
};

export default MyHelmet;
