import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthContext from '../../shared/context/auth/authContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const ProtectedRoute = ({ children, requiredRole, ...rest }) => {
  const { role } = useContext(AuthContext);
  const access = requiredRole.some((e) => e === role);

  return (
    <>
      {role ? (
        <Route
          {...rest}
          render={({ location }) =>
            access ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            )
          }
        />
      ) : (
        <LoadingSpinner asOverlay />
      )}
    </>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  requiredRole: PropTypes.arrayOf(String).isRequired,
};

export default ProtectedRoute;
