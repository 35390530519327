const theme = {
  // Colors moving from brightest to darkest

  // Decoration colors:
  decFirst: "#edb5bf",
  decSecond: "#59253A",
  decThird: "#331121",

  // Background colors:
  bgFirst: "#FFFFFF",
  bgSecond: "#ECECEC",
  bgThird: "#EDEAE5",
  bgError: "#FF9494",

  // Text colors:
  textFirst: "#F3F3F3",
  textSecond: "#515154",
  textThird: "#0C0C0C",
  textError: "#ff3333",

  // Special Colors:
  tableBorder: "lightgrey",
  footerBg: "#262626",
  footerText: "#adadad",

  font: {
    size: {
      xxs: "0.8rem",
      xs: "1.0rem",
      s: "1.2rem",
      sm: "1.4rem",
      m: "1.6rem",
      sl: "1.8rem",
      l: "2.4rem",
      xl: "3.2rem",
      xxl: "4.2rem",
      xxxl: "5.2rem",
    },
  },
  mq: {
    bigPhone: `@media (min-width: 361px)`,
    tablet: `@media (min-width: 700px)`,
    desktop: `@media (min-width: 1025px)`,
    bigDesktop: `@media (min-width: 1300px)`,
    hugeDesktop: `@media (min-width: 1600px)`,
    max: `@media (min-width: 1921px)`,
  },
};

export default theme;
