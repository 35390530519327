import React from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../hooks/authHook';
import AuthContext from './authContext';

const AuthContextProvider = ({ children }) => {
  const {
    isLoggedIn,
    uToken,
    uId,
    user,
    role,
    avatar,
    setAvatar,
    updateMe,
    login,
    logout,
  } = useAuth();

  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn,
          token: uToken,
          userId: uId,
          me: user,
          role,
          avatar,
          setAvatar,
          updateMe,
          login,
          logout,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
